/**
* Web Application Settings
*/

.App {
    text-align: center;
}

.navigation {
    height: 5.5rem;
    padding: 1rem;
    font-size: 5rem;
    background-color: #000716;
}

#navbar {
    position: sticky;
    z-index: 1003;
    background-color: #16191f;
}

.header-font-white {
    color: white;
}

.btn {
    color: #ffffff !important;
    background-color: #007993 !important;
    border-color: #066173 !important;
    border-width: thin !important;
}

/**
* AWS Amplify Settings
*/

.amplify-tabs {
    display: none; /* This CSS hide the signUp tab*/
}

.amplify-icon {
    color: white;
}

[data-amplify-container] {
    margin-top: 2rem;
}

:root {
    --amplify-components-fieldcontrol-small-font-size: 1.5rem;
    --amplify-components-button-font-size: 1.5rem;
    --amplify-components-button-background-color: #007993;
    --amplify-components-button-border-color: #354150;
    --amplify-components-fieldcontrol-font-size: 1.5rem;
    --amplify-components-tabs-item-font-size: 1.5rem;
}
